import React, { useState } from 'react';
import { Map } from 'immutable';
import LoadingButton from '@mui/lab/LoadingButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ActionData = {
  postInstall: Map({
    'wpmu': 'Upload latest WPMU plugins',
    'delete-pp-downsized': 'Delete ProPhoto downsized images',
    'disable-legacy-autoloading': 'Disable autoloading of legacy ProPhoto options',
  }),
  misc: Map({
    'prophoto': 'Upload latest version of p8',
    'thumbnails': 'Generate missing WordPress thumbnails',
    'migrate-wp-blocks': 'Migrate grid/gallery block data',
    'delete-pp-cache-files': 'Delete Legacy ProPhoto cache files',
    'smash-insta-plugin': 'Install/register Smash Instagram plugin',
  }),
};

const WPEActions = ({ scope, runAction, runningAction }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [replaceTerm, setReplaceTerm] = useState('');

  return (
    <div className="WPE__actions">
      <h3>Post install tasks</h3>
      <div className="WPE__actions__column">
        {ActionData.postInstall.map((text, action) => {
          return (
            <LoadingButton
              key={action}
              size="small"
              onClick={() => runAction(scope, action)}
              variant="contained"
              disabled={!!runningAction}
              endIcon={<SendIcon />}
              loading={runningAction === action}
              loadingPosition="end"
            >
              {text}
            </LoadingButton>
          );
        }).valueSeq()}
      </div>
      <Accordion className="WPE__actions__searchReplace">
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Search/Replace</AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth required>
            <TextField label="Searh for this" variant="outlined" value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
          </FormControl>
          <FormControl fullWidth required>
            <TextField label="Replace with this" variant="outlined" value={replaceTerm} onChange={e => setReplaceTerm(e.target.value)} />
          </FormControl>
          <LoadingButton
            size="small"
            onClick={() => runAction(scope, 'search-replace', { searchTerm, replaceTerm })}
            variant="outlined"
            disabled={!!runningAction}
            endIcon={<SendIcon />}
            loading={runningAction === 'search-replace'}
            loadingPosition="end"
          >
            Test Search &amp; Replace
          </LoadingButton>
          <LoadingButton
            size="small"
            onClick={() => {
              if (window.confirm(`Are you sure you want to replace ${searchTerm} with ${replaceTerm} in the database for the site ${scope}`)) {
                runAction(scope, 'search-replace', { searchTerm, replaceTerm, bypassDryRun: true });
              }
            }}
            variant="contained"
            disabled={!!runningAction}
            endIcon={<SendIcon />}
            loading={runningAction === 'search-replace'}
            loadingPosition="end"
          >
            Run Search &amp; Replace
          </LoadingButton>
        </AccordionDetails>
      </Accordion>
      <h3>Other miscellaneous tasks</h3>
      <div className="WPE__actions__column">
        {ActionData.misc.map((text, action) => {
          return (
            <LoadingButton
              key={action}
              size="small"
              onClick={() => runAction(scope, action)}
              variant="outlined"
              disabled={!!runningAction}
              endIcon={<SendIcon />}
              loading={runningAction === action}
              loadingPosition="end"
            >
              {text}
            </LoadingButton>
          );
        }).valueSeq()}
      </div>
    </div>
  );
}

export default WPEActions;
